<template>
    <div>
        <div v-if="showContent" class="loader-container">
            <div class="loader"></div>
        </div>
    </div>
    <QuickViewErrore v-if="Quickerrore"></QuickViewErrore>
</template>


<script>

// Ottieni la query string dall'URL
var queryString = window.location.search;
queryString = queryString.substring(1);

// Dividi la stringa in un array di coppie nome=valore
var parametri = queryString.split("&");

// Crea un oggetto per memorizzare i parametri
var parametriObj = {};
for (var i = 0; i < parametri.length; i++) {
    var coppia = parametri[i].split("=");
    parametriObj[coppia[0]] = coppia[1];
}

import axios from 'axios';
import QuickViewErrore from '@/components/QuickViewErrore.vue';


export default {
    name: 'MyOrder',
    inject: ["eventBus"],

    data() {
        return {
            showContent: true,
            Quickerrore: false
        }
    },

    created() {
        this.getBillItem()
    },


    methods: {
        async getBillItem() {
            switch (parametriObj.contaprezzi) {
                case 'true':
                    sessionStorage.setItem('MatchUser', parametriObj.match)
                    sessionStorage.setItem('Bill', parametriObj.idData)
                    sessionStorage.setItem('Coperti', "")
                    this.$router.push("/contaprezzi")
                    break;

                default:
                    sessionStorage.setItem('MatchUser', parametriObj.match)
                    sessionStorage.setItem('Username', parametriObj.user)
                    sessionStorage.setItem('TipoOrdine', parametriObj.type)
                    sessionStorage.setItem('Bill', parametriObj.idData)
                    var callURL ='/billdetails/'
                    if (parametriObj.filtroOrd == 'PRE') {
                        sessionStorage.setItem('filtro', (parametriObj.filtroOrd === '-1' ? "" : parametriObj.filtroOrd))
                        callURL = '/prenotazione/'
                    } else {
                        sessionStorage.setItem('filtro', "")
                    }
                    try {var items = await axios.get(callURL + parametriObj.idData)
                        if (items.errMsg) {this.Quickerrore = true; return; }} catch (error) {this.Quickerrore = true; return;
                    }
                    for (let i = 0; i < items.data.length; i++) {
                        let data = {
                            user_id: sessionStorage.getItem('Username'),
                            food_id: items.data[i].food_id,
                            item_qty: parseInt(items.data[i].item_qty)
                        }
                        await axios.post("/cartItem", data);
                    }
                    this.eventBus.emit("showCart", true);
                    this.$router.push("/menu")
                    break;
            }

        },

    },

    components: { QuickViewErrore }
}
</script>

<style scoped>
.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    height: 75vh;
}

.loader {
    border: 8px solid #fff;
    border-top: 8px solid #27ae60;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>